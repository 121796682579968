<template>
  <section>
    <button-back
      v-if="$route.query.event"
      @click="goBack"
    >
      Back to League
    </button-back>

    <b-nav pills>
      <!-- Information -->
      <b-nav-item
        exact
        active-class="active"
        :to="{ name: 'organization.venue.information', query: $route.query }"
        :active="$route.name === 'organization.venue.information' || $route.name === 'organization.venue.create'"
      >
        <font-awesome-icon
          icon="fa-light fa-circle-info"
          size="lg"
          fixed-width
        />
        Info
      </b-nav-item>

      <!-- Playing Surfaces  -->
      <b-nav-item
        exact
        active-class="active"
        :disabled="!venue"
        :to="{ name: 'organization.venue.playing-surfaces', query: $route.query }"
      >
        <font-awesome-icon
          :icon="icon"
          size="lg"
          fixed-width
        />
        {{ playingSurfaceType }}s
      </b-nav-item>
    </b-nav>

    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <router-view
        v-if="!loading"
        :information="venue"
        :go-back="goBack"
        @update="venue = $event"
      />
    </transition>
  </section>
</template>

<script>
import { BNav, BNavItem } from 'bootstrap-vue'
import ButtonBack from '@/components/buttons/ButtonBack.vue'

import axios from '@/libs/axios'
import store from '@/store'
import { mapState } from 'vuex'
import useFlash from '@/composables/useFlash'
import useAppConfig from '@core/app-config/useAppConfig'

const { flashError } = useFlash()

export default {
  components: {
    BNav,
    BNavItem,
    ButtonBack,
  },
  setup() {
    const { routerTransition } = useAppConfig()

    return {
      routerTransition,
    }
  },
  data() {
    return {
      venue: null,
      loading: true,
    }
  },
  computed: mapState('app/sport', ['playingSurfaceType', 'icon']),
  beforeRouteEnter(to, from, next) {
    store.commit('app/sport/UPDATE_SPORT', to.query.sport)

    if (!to.params.venue) {
      next(vm => {
        vm.loading = false
      })

      return
    }

    axios.get(`/venues/${to.params.venue}`)
      .then(response => {
        const { data } = response.data

        next(vm => {
          vm.venue = data
          vm.loading = false
        })
      })
      .catch(error => {
        flashError(error.message)
      })
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'event.venues', params: { event: this.$route.query.event } })
    },
  },
}
</script>

<style scoped>

</style>
